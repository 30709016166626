.main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: rgba(0, 128, 128);
}

.layout {
  margin-top: 12px;
  margin-bottom: 60px;
}

@media (min-width: 550px) {
  .layout {
    margin-right: 24px;
    margin-left: 24px;
    margin-top: 24px;
  }
}

.cuVjNa {
  overflow-x: auto;
}

.fiXavk:before {
  width: max-content !important;
}
